import React, { useState, useEffect } from "react";
import { Button, Snackbar, Box } from "@mui/material";
import "./CookieConsent.css";

const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    const hasConsented = localStorage.getItem("cookieConsent");
    if (!hasConsented) {
      setShowConsent(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "true");
    setShowConsent(false);
  };

  const handleDecline = () => {
    setShowConsent(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "10vh",
        position: "fixed",
      }}
    >
      <Snackbar
        open={showConsent}
        message="Acest site utilizează cookie-uri. Prin continuarea navigării, acceptati utilizarea acestora."
        action={
          <>
            <Button
              color="primary"
              variant="contained"
              onClick={handleAccept}
              className="button"
            >
              Accept
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleDecline}
              className="button"
            >
              Refuz
            </Button>
          </>
        }
      />
    </Box>
  );
};

export default CookieConsent;
