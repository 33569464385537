import React from "react";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import WhatsappBubble from "./WhatsappBubble";
import FacebookBubble from "./FacebookBubble";
import PhoneBubbles from "./PhoneBubbles";


const ContactBubbles = () => {
  const phoneNumber = "+40741503698";


  return (
    <div
      style={{
        position: "fixed",
        bottom: "20px",
        right: "10px",
        zIndex: "9999",
      }}
    >
      <PhoneBubbles />
      <WhatsappBubble />
      <FacebookBubble />
    </div>
  );
};

export default ContactBubbles;
