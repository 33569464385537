import React from 'react';
import { Helmet } from 'react-helmet';

const StructuredData = () => (
    <Helmet>
        <script type="application/ld+json">
            {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Recondcazi",
          "url": "https://recondcazi.ro",
          "logo": "https://recondcazi.ro/static/media/logo.png",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+40741503698",
            "contactType": "customer service"
          },
          "address": {
            "@type": "PostalAddress",
            "addressLocality": "Galați, Brăila, Buzău, Ploiești, București",
            "addressCountry": "RO"
          }
        }
      `}
        </script>
    </Helmet>
);

export default StructuredData;
