import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

import "./GallerySlider.css";
import recond_1 from "../media/images/reconditionari/recond_1.webp";
import recond_2 from "../media/images/reconditionari/recond_2.webp";
import recond_3 from "../media/images/reconditionari/recond_3.webp";
import recond_4 from "../media/images/reconditionari/recond_4.webp";
const itemData = [
  {
    img: recond_1,
    title: "Recond",
  },
  {
    img: recond_4,
    title: "Recond",
  },
  {
    img: recond_2,
    title: "Recond",
  },
  {
    img: recond_3,
    title: "Recond",
  },
];

const GallerySlider = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  // const [currentIndex, setCurrentIndex] = useState(0);

  // const handleImageClick = (index) => {
  //   setSelectedImage(itemData[index]);
  // };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  // const handleCarouselChange = (index) => {
  //   console.log(index);
  //   if (index === itemData.length - 1) {
  //     setCurrentIndex(0); // Resetați indexul la început
  //     console.log("schimbat", currentIndex);
  //   } else {
  //     setCurrentIndex(index);
  //   }
  // };

  return (
    <>
      <Carousel
        showThumbs={false}
        autoPlay={true}
        infiniteLoop
        // index={currentIndex}
        // onChange={handleCarouselChange}
      >
        {itemData.map((item, index) => (
          <div key={item.img}>
            <img
              src={item.img}
              key={index}
              alt={item.title}
              className=".carousel .slide img"
            />
          </div>
        ))}
      </Carousel>
      {/* <Dialog
        open={!!selectedImage}
        onClose={handleCloseModal}
        maxWidth="md"
        PaperProps={{
          style: {
            overflow: "hidden",
            borderRadius: 0,
            margin: "0",
            boxShadow: "none",
          },
        }}
      >
        <DialogContent style={{ padding: "0", overflow: "hidden" }}>
          <img
            src={selectedImage?.img}
            alt={selectedImage?.title}
            style={{ maxWidth: "100%", height: "auto", overflow: "hidden" }}
          />
        </DialogContent>
        <DialogActions style={{ margin: "0", padding: "1px 1px" }}>
          <Button onClick={handleCloseModal}>Close</Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
};

export default GallerySlider;
