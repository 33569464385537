import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase";

const WhatsappBubble = () => {
    const whatsappNumber = "+40750728652";

    const handleWhatsappClick = async () => {
        try {
            const dataToSave = {
                timestamp: new Date(),
                action: 'button_click',
            };

            // Adaugare log în colecția "WhatsappPress"
            await addDoc(collection(db, 'WhatsappPress'), dataToSave);

            // Trimitere eveniment către Google Analytics
            if (window.gtag) {
                window.gtag('event', 'whatsapp_click', {
                    event_category: 'engagement',
                    event_label: 'whatsapp_bubble_click',
                });
            }

            // Deschidere WhatsApp
            const whatsappURL = `https://wa.me/${whatsappNumber}`;
            window.open(whatsappURL, "_blank");
        } catch (error) {
            console.error('Eroare la salvarea logului:', error);
        }
    };

    return (
        <div
            onClick={handleWhatsappClick}
            style={{
                background: "#25D366",
                borderRadius: "50%",
                width: "50px",
                height: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                marginTop: "5px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
            }}
        >
            <WhatsAppIcon sx={{ fontSize: 40, color: "white" }} />
        </div>
    );
};

export default WhatsappBubble;
