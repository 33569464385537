import React from "react";

import PhoneIcon from "@mui/icons-material/Phone";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Box } from "@mui/system";
import { FormLabel, Typography, Link } from "@mui/material";
const ContactBar = () => {
  const phoneNumber1 = "+40741503698";
  const phoneNumber2 = "+40741503698";

  // const handlePhone1Click = () => {
  //   window.location.href = `tel:${phoneNumber1}`;
  // };

  // const handlePhone2Click = () => {
  //   window.location.href = `tel:${phoneNumber2}`;
  // };

  return (
    <Box
      style={{
        backgroundColor: "blues",
        padding: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginRight: "10px",
        }}
      >
        <Typography
          variant="h6"
          style={{ marginBottom: "5px", fontWeight: "bold" }}
        >
          Contact
        </Typography>
        <Typography style={{ fontWeight: "bold" }}>
          RECONDITIONARI CAZI COJI LEGENDE.SRL
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography
            variant="body2"
            style={{ marginBottom: "5px", fontWeight: "bold" }}
          >
            Telefon:{" "}
          </Typography>
          <Typography
            variant="body2"
            style={{ marginBottom: "5px", fontWeight: "bold", color: "red" }}
          >
            <Link style={{ color: "#fff" }} href={`tel:${phoneNumber1}`}>
              {phoneNumber1}
            </Link>
          </Typography>
          <Typography
            variant="body2"
            style={{ marginBottom: "5px", fontWeight: "bold" }}
          >
            <Link style={{ color: "#fff" }} href={`tel:${phoneNumber2}`}>
              {phoneNumber2}
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ContactBar;
