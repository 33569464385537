import React from 'react';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Grid, Paper, Typography, Link, FormLabel } from '@mui/material';
import GallerySlider from '../Components/GallerySlider';
import StructuredData from '../StructuredData';


const Item = styled(Paper)(({ theme }) => ({
    background: `rgba(33, 99, 164, 0.7)`,
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    height: "100%",
    borderRadius: "10px",
    color: theme.palette.text.secondary,
}));

const Home = () => {
    return (
        <>
            <Helmet>
                <title>Recondiționări Căzi de Baie - Servicii Profesionale | Recondcazi.ro</title>
                <meta name="description" content="Oferim servicii complete de recondiționare a căzilor de baie, transformându-le în piese care emană frumusețe și eleganță. Contactați-ne pentru mai multe detalii!" />
                <meta name="keywords" content="recondiționare căzi de baie, restaurare băi, servicii recondiționare, renovare căzi de baie" />
                <meta property="og:title" content="Recondiționări Căzi de Baie - Servicii Profesionale" />
                <meta property="og:description" content="Oferim servicii complete de recondiționare a căzilor de baie, cu o experiență de peste 6 ani. Contactați-ne pentru detalii!" />
                <meta property="og:image" content="https://recondcazi.ro/static/media/logo.5b9f4efce818d2a77cec.png" />
                <meta property="og:url" content="https://recondcazi.ro/" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="ro_RO" />
            </Helmet>
            <StructuredData /> {/* Include componenta StructuredData */}
            <Box sx={{ margin: "20px" }}>
                {/* <Typography variant="h1" sx={{ textAlign: 'center', color: "yellow", fontSize: "4vh" }}>
                    Recondiționări Căzi de Baie
                </Typography> */}
                <FormLabel style={{ display: 'flex', justifyContent: 'center', whiteSpace: 'nowrap', color: "Yellow", fontSize: "4vh" }}>Galați-Brăila-Buzău</FormLabel>
                <FormLabel style={{ display: 'flex', justifyContent: 'center', whiteSpace: 'nowrap', color: "Yellow", fontSize: "4vh" }}>Ploiești-București</FormLabel>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} md={4} sx={{ marginBottom: '30px' }}>
                        <Item>
                            <Typography variant="h5" sx={{ color: 'white' }}>
                                Recondiționam căzi de baie
                            </Typography>
                            <Typography sx={{ color: 'white', marginTop: '10px' }}>
                                Oferim servicii complete de recondiționare a căzilor de baie, transformându-le în piese care emană frumusețe și eleganță. Indiferent de materialul din care sunt fabricate, fie că este vorba de fontă, acrilic sau tablă, suntem specializați în restaurarea și revitalizarea lor.
                            </Typography>

                            <Typography variant="h6" component="div">
                                <Link href="tel:+40741503698" sx={{ color: 'yellow' }}>
                                    Telefon +40741503698
                                </Link>
                            </Typography>
                        </Item>
                    </Grid>

                    <Grid item xs={12} md={4} sx={{ marginBottom: '30px' }}>
                        <Item>
                            <Typography variant="h5" sx={{ color: "white" }}>Cea mai bună calitate</Typography>
                            <Typography sx={{ color: 'white', marginTop: '10px' }}>
                                În cadrul procesului nostru meticulos de recondiționare a căzilor de baie, folosim un email de cea mai înaltă calitate, creat special pentru a oferi durabilitate și strălucire.
                            </Typography>
                            <Typography sx={{ color: 'white', marginTop: '10px' }}>
                                Noi nu ne compromitem calitatea, așa că selectăm cu grijă emailul profesional, achiziționat direct de la producători de renume, garantându-vă un rezultat de excepție pentru următorii 15 ani.
                            </Typography>
                        </Item>
                    </Grid>

                    <Grid item xs={12} md={4} sx={{ marginBottom: '30px' }}>
                        <Item>
                            <Typography variant="h5" sx={{ color: "white" }}>Experiență de peste 6 ani</Typography>
                            <Typography sx={{ color: "white" }}>Ne dedicăm cu pasiune și măiestrie fiecărui proiect pe care-l abordăm, readucând cazile de baie la viață și transformându-le în adevărate opere de artă.</Typography>
                            <Typography sx={{ color: 'white' }}>
                                Utilizăm tehnici de recondiționare de ultimă generație, împreună cu materiale de cea mai înaltă calitate, pentru a vă oferi rezultate impecabile.
                            </Typography>
                            <Typography variant="h5" gutterBottom sx={{ color: 'white', marginTop: '10px' }}>
                                Garanție de 4 ani
                            </Typography>
                        </Item>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ marginBottom: '30px' }}>
                        <Item>
                            <GallerySlider />
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default Home;
