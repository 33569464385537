import React from "react";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import WhatsappBubble from "./WhatsappBubble";
import FacebookBubble from "./FacebookBubble";
import { db } from "../firebase";
import { doc, getDoc, setDoc, addDoc, collection } from "firebase/firestore";

const PhoneBubbles = () => {
    const phoneNumber = "+40741503698";

    const handlePhoneClick = async () => {
        try {
            const dataToSave = {
                timestamp: new Date(),
                action: 'button_click',
            };

            // Adaugare log în colecția "logs" din containerul "phoneCallLogs"
            await addDoc(collection(db, 'phoneCallLogs'), dataToSave);

            // Trimitere eveniment către Google Analytics
            if (window.gtag) {
                window.gtag('event', 'phone_call', {
                    event_category: 'engagement',
                    event_label: 'phone_bubble_click',
                });
            }

            // Redirecționare către apel telefonic
            window.location.href = `tel:${phoneNumber}`;
        } catch (error) {
            console.error('Eroare la salvarea logului:', error);
        }
    };

    return (
        <div
            onClick={handlePhoneClick}
            style={{
                background: "#e65c00",
                borderRadius: "50%",
                width: "50px",
                height: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
            }}
        >
            <PhoneEnabledIcon sx={{ fontSize: 40, color: "white" }} />
        </div>
    );
};

export default PhoneBubbles;
