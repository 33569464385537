import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";

const FacebookBubble = () => {
    const facebookURL = "https://www.facebook.com/people/RecondCazi/61558380714159/";

    const handleFacebookClick = () => {
        // Trimitere eveniment către Google Analytics
        if (window.gtag) {
            window.gtag('event', 'facebook_click', {
                event_category: 'engagement',
                event_label: 'facebook_bubble_click',
            });
        }

        // Deschidere Facebook
        window.open(facebookURL, "_blank");
    };

    return (
        <div
            onClick={handleFacebookClick}
            style={{
                background: "#1877F2",
                borderRadius: "50%",
                width: "50px",
                height: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                marginTop: "5px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
            }}
        >
            <FacebookIcon sx={{ fontSize: 40, color: "white" }} />
        </div>
    );
};

export default FacebookBubble;
