import './App.css';
import React from 'react';
import { styled } from '@mui/system';
import { AppBar, Box, Container, Paper, Toolbar } from '@mui/material';
import Home from './Pages/Home';
import BackgroundImage from './media/images/image-background.webp';
import ContactBubbles from './Components/ContactBubbles';
import { useCookies } from 'react-cookie';
import CookieConsent from './Components/CookieConsent';
import Logo from "./media/images/logo.png";
import ContactBar from './Components/Contact';
import { Helmet } from 'react-helmet';

const Background = styled(Paper)`
  background-image: url(${BackgroundImage});
  filter: blur(0px);
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0.5em;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

const App = () => {
  return (
    <>
      <Helmet>
        <title>Servicii Complete de Recondiționare a Căzilor de Baie - Recondcazi.ro</title>
        <meta name="description" content="Oferim servicii complete de recondiționare a căzilor de baie, transformându-le în piese care emană frumusețe și eleganță." />
        <meta name="keywords" content="recondiționare căzi de baie, reparații căzi, servicii de recondiționare băi" />
        <meta property="og:title" content="Recondiționări Căzi de Baie" />
        <meta property="og:description" content="Oferim servicii complete de recondiționare a căzilor de baie, transformându-le în piese care emană frumusețe și eleganță." />
        <meta property="og:image" content="https://recondcazi.ro/static/media/logo.5b9f4efce818d2a77cec.png" />
        <meta property="og:url" content="https://recondcazi.ro/" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="ro_RO" />
      </Helmet>
      <Box sx={{ flexGrow: 1, overflow: "auto" }}>
        <AppBar position="fixed">
          <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={Logo}
              alt="Recondiționări Căzi de Baie"
              style={{ maxWidth: '300px' }}
            />
          </Toolbar>
        </AppBar>

        <Background>
          <Box style={{ paddingTop: '64px' }}>
            <Home />
          </Box>
          <ContactBar />
        </Background>
        <CookieConsent />
        <ContactBubbles />
      </Box>
    </>
  );
}

export default App;
